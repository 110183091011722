// mixins/currencyRouteMixin.js
export default {
  computed: {
    currentCurrency() {
      return this.$store.state.currency.currency;
    },
  },
  methods: {
    ensureCurrencyInQuery() {
      if (
        this.currentCurrency &&
        this.$route.query["currency:in"] !== this.currentCurrency
      ) {
        // Conserver tous les paramètres existants
        const newQuery = {
          ...this.$route.query,
          "currency:in": this.currentCurrency,
        };

        // S'assurer que page et limit sont présents
        if (!newQuery.page) newQuery.page = "1";
        if (!newQuery.limit) newQuery.limit = "10";

        this.$router.replace({ query: newQuery });
      }
    },
  },
  created() {
    this.ensureCurrencyInQuery();
  },
  watch: {
    currentCurrency: {
      handler(newCurrency) {
        if (newCurrency) {
          this.ensureCurrencyInQuery();
        }
      },
      immediate: true,
    },
    // Ajouter un watcher sur la route
    $route: {
      handler() {
        this.ensureCurrencyInQuery();
      },
      deep: true,
    },
  },
};
