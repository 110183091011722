// store/currency.js
import API from '@/api/api-admin';

const DEFAULT_CURRENCY = 'EUR';

const state = {
    currency: localStorage.getItem('userCurrency') || DEFAULT_CURRENCY,
    economicZoneClients: [],
    loadingEconomicZoneClients: false,
    economicZoneClientsError: null
};

const getters = {
    currentCurrency: state => state.currency,
    getEconomicZoneClients: state => state.economicZoneClients
};

const mutations = {
    SET_CURRENCY(state, currency) {
        state.currency = currency;
    },
    SET_ECONOMIC_ZONE_CLIENTS(state, clients) {
        state.economicZoneClients = clients;
    },
    SET_ECONOMIC_ZONE_CLIENTS_LOADING(state, status) {
        state.loadingEconomicZoneClients = status;
    },
    SET_ECONOMIC_ZONE_CLIENTS_ERROR(state, error) {
        state.economicZoneClientsError = error;
    }
};

const actions = {
    async updateCurrency({ commit, dispatch }, currency) {
        commit('SET_CURRENCY', currency);
        localStorage.setItem('userCurrency', currency);
        await dispatch('fetchEconomicZoneClients', currency);
    },

    async initCurrency({ commit, dispatch }) {
        const savedCurrency = localStorage.getItem('userCurrency');
        
        if (!savedCurrency) {
            localStorage.setItem('userCurrency', DEFAULT_CURRENCY);
            commit('SET_CURRENCY', DEFAULT_CURRENCY);
        } else {
            commit('SET_CURRENCY', savedCurrency);
        }
       
        await dispatch('fetchEconomicZoneClients', state.currency);
    },

    async fetchEconomicZoneClients({ commit }, currency) {
        try {
            commit('SET_ECONOMIC_ZONE_CLIENTS_LOADING', true);
            const response = await API.get(`/economic-zone/${currency.toLowerCase()}/clients?=`);
            commit('SET_ECONOMIC_ZONE_CLIENTS', response.data);
        } catch (error) {
            commit('SET_ECONOMIC_ZONE_CLIENTS_ERROR', error.message);
            commit('SET_ECONOMIC_ZONE_CLIENTS', []);
        } finally {
            commit('SET_ECONOMIC_ZONE_CLIENTS_LOADING', false);
        }
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};