var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"template-goldenmetrics"},[_c('PageHeader',{attrs:{"title":_vm.title}}),_c('PageFilters',{scopedSlots:_vm._u([{key:"left",fn:function(){return [(_vm.countriesOptions && _vm.countriesOptions.length)?_c('FieldSearchSelect',{attrs:{"model":_vm.selectecCountries,"options":_vm.countriesOptions,"label":_vm.$t('global.country'),"disabled":_vm.countriesOptions[0]?.value === 'NO_COUNTRIES'},on:{"change":(e) => (_vm.selectecCountries = !e.length ? ['ALL-COUNTRIES'] : e)}}):_vm._e(),(!_vm.isPeriod)?_c('FieldDate',{attrs:{"disabled-today":true,"label":_vm.$t('global.date'),"model":_vm.period1Start},on:{"change":(e) => {
            _vm.period1Start = e;
            _vm.getMetrics();
          }}}):_vm._e(),(_vm.isPeriod)?_c('FieldPeriod',{attrs:{"start":_vm.period1Start,"end":_vm.period1End,"highlight":_vm.rangePeriod2,"label":_vm.$t('goldenmetrics.period1'),"disabled-today":true,"dataTestid":'popin-calendar1'},on:{"change":(e) => {
            _vm.period1Start = e.start;
            _vm.period1End = e.end;
            _vm.updatePeriod2(e.comparaison);
            _vm.getMetrics();
          }}}):_vm._e(),(_vm.isPeriod)?_c('FieldPeriod',{attrs:{"start":_vm.period2Start,"end":_vm.period2End,"highlight":_vm.rangePeriod1,"label":_vm.$t('goldenmetrics.compare'),"disabled-today":true,"hide-suggestion":true,"dataTestid":'popin-calendar2',"text":_vm.type !== 'period'
            ? _vm.$t(`${_vm.type === 'none' ? 'global' : 'goldenmetrics'}.${_vm.type}`)
            : null},on:{"change":(e) => {
            _vm.period2Start = e.start;
            _vm.period2End = e.end;
            _vm.getMetrics();
          }}},[_c('li',{class:{ 'is-active': _vm.type === 'period' },on:{"click":function($event){$event.preventDefault();_vm.type = 'period'}}},[_vm._v(" "+_vm._s(_vm.$t("global.custom"))+" ")]),(false)?_c('li',{class:{ 'is-active': _vm.type === 'benchmark' },on:{"click":function($event){$event.preventDefault();_vm.type = 'benchmark'}}},[_vm._v(" "+_vm._s(_vm.$t("goldenmetrics.benchmark"))+" ")]):_vm._e(),_c('li',{class:{ 'is-active': _vm.type === 'none' },on:{"click":function($event){$event.preventDefault();_vm.type = 'none'}}},[_vm._v(" "+_vm._s(_vm.$t("global.none"))+" ")])]):_vm._e()]},proxy:true},{key:"right",fn:function(){return [_c('Button',{attrs:{"icon":"config","color":"grey","data-testid":"popin-filter-categorie"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.$refs.popinMetrics.open()}}},[_vm._v(_vm._s(_vm.$t("metrics.title")))]),_c('Button',{attrs:{"icon":"download","color":"grey","data-testid":"popin-filter-export"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.exportData.apply(null, arguments)}}})]},proxy:true}])}),_c('PopinMetrics',{ref:"popinMetrics",attrs:{"fields":_vm.selectedCategories,"all-fields":_vm.allCategories},on:{"change":(e) => (_vm.selectedCategories = e)}}),(_vm.allowedClients && !_vm.isClient)?_c('FieldSearchSelect',{attrs:{"model":_vm.selectedClients,"label":_vm.$t('global.client'),"options":_vm.allowedClients.map((c) => ({ name: c.name, value: c.id }))},on:{"change":(e) => (_vm.selectedClients = e)}}):_vm._e(),(!_vm.goldenMetrics)?_c('PageLoader'):_c('div',[_c('Checkerboard',{attrs:{"data-testid":"checkerboard"}},_vm._l((_vm.selectedCategories),function(category){return _c('CheckerboardItem',{key:category.name,attrs:{"set":(_vm.stat = _vm.getEvolutionStatistic(category.name)),"statistic":_vm.stat,"statistic-color":category.name.includes('return')
            ? _vm.stat > 0
              ? 'red'
              : 'green'
            : null,"image":category.icon ? `/img/goldenmetrics/${category.icon}.svg` : null,"info":_vm.getInfo(category.name),"title":_vm._f("goldenMetrics")(_vm.goldenMetrics && _vm.goldenMetrics.sum && _vm.goldenMetrics.sum[0]
            ? _vm.goldenMetrics.sum[0][category.name]
            : null,category.format),"subtitle":_vm.getTitle(category.name)}})}),1),(
        _vm.allowedGoldenMetricsClients &&
        _vm.allowedGoldenMetricsClients.length === 1 &&
        _vm.isPeriod
      )?_c('GoldenmetricsChartLines',{ref:"clientChart",attrs:{"selected-categories":_vm.selectedCategories,"selected-category":_vm.selectedCategory,"stats":_vm.allowedGoldenMetricsClients[0][_vm.selectecCountries[0]].stats.slice(
          0,
          _vm.displayStatistic ? 2 : 1
        ),"category":_vm.selectedCategory?.name,"legend2":_vm.legend2},on:{"updatePeriod":(e) => (_vm.chartPeriodSelected = e),"change":(e) => (_vm.selectedCategory = _vm.allCategories.find((c) => c.name === e))}}):(
        _vm.isPeriod || (!_vm.isPeriod && _vm.allowedGoldenMetricsClients.length > 1)
      )?_c('SectionCategories',{attrs:{"categories":_vm.selectedCategories,"selected":_vm.selectedCategory.name,"legend1":_vm.$t('chart.period1'),"legend2":_vm.legend2},on:{"change":(e) => (_vm.selectedCategory = _vm.allCategories.find((c) => c.name === e))}},_vm._l((_vm.allowedGoldenMetricsClients),function(client){return _c('ChartBarRow',{key:client.client.name + _vm.selectecCountries[0] + _vm.selectedCategory.name,attrs:{"text":client.client.name,"statistic":_vm.getClientStatistic(client),"max":_vm.categoryMaxValue,"format":_vm.selectedCategory.format,"category":_vm.selectedCategory.title,"legend1":_vm.$t('chart.period1'),"legend2":_vm.legend2,"value1":_vm.getPeriodValue(client, 0),"value2":_vm.displayStatistic ? _vm.getPeriodValue(client, 1) : null}})}),1):_vm._e(),(_vm.allowedGoldenMetricsClients?.length === 1)?_c('TableSticky',{staticClass:"template-goldenmetrics__sticky--client",class:{
        'template-goldenmetrics__sticky--periods':
          _vm.displayStatistic && _vm.isPeriod,
      },attrs:{"fields":[
        { key: 'client', size: 'l' },
        { key: 'period', translation: 'goldenmetrics.period' },
        ..._vm.selectedCategories.map((c) => ({
          key: c.name,
          translation: `goldenmetrics.${c.name}`,
        })),
      ]}},[_c('div',[_c('TableStickyRow',[_c('Cell',{staticClass:"is-size--l",attrs:{"value":_vm.allowedGoldenMetricsClients[0].client.name,"tag":"div"}}),_c('Cell',{attrs:{"value":_vm.$t('goldenmetrics.period1'),"tag":"div"}}),_vm._l((_vm.selectedCategories),function(category){return _c('Cell',{key:category.name,attrs:{"value":_vm.getClientsStatSum(0, category.name),"type":category.format,"tag":"div"}})})],2),(_vm.isPeriod && _vm.displayStatistic)?_c('TableStickyRow',[_c('Cell',{staticClass:"is-size--l",attrs:{"value":" ","tag":"div"}}),(false)?_c('Cell',{attrs:{"value":_vm.$t(
                `goldenmetrics.${
                  _vm.type === 'benchmark' ? 'benchmark' : 'period2'
                }`
              ),"tag":"div"}}):_vm._e(),_vm._l((_vm.selectedCategories),function(category){return _c('Cell',{key:category.name,attrs:{"value":_vm.getClientsStatSum(1, category.name),"type":category.format,"tag":"div"}})})],2):_vm._e()],1),_vm._l((Object.keys(
          _vm.allowedGoldenMetricsClients[0][_vm.selectecCountries[0]].stats[0]
            .details[_vm.chartPeriodSelected]
        ).slice(0, 31)),function(day,i){return _c('div',{key:i},[_c('TableStickyRow',[_c('Cell',{staticClass:"is-size--l",attrs:{"value":" ","tag":"div"}}),_c('Cell',{attrs:{"value":day,"tag":"div"}}),_vm._l((_vm.selectedCategories),function(category){return _c('Cell',{key:category.name,attrs:{"value":_vm.getClientStatDetails(0, day, category.name),"type":category.format,"tag":"div"}})})],2),(_vm.isPeriod && _vm.displayStatistic)?_c('TableStickyRow',{attrs:{"set":(_vm.date = Object.keys(
              _vm.allowedGoldenMetricsClients[0][_vm.selectecCountries[0]].stats[1]
                .details[_vm.chartPeriodSelected]
            )[i])}},[_c('Cell',{staticClass:"is-size--l",attrs:{"value":" ","tag":"div"}}),(false)?_c('Cell',{attrs:{"value":_vm.type === 'benchmark' ? _vm.$t('goldenmetrics.benchmark') : _vm.date,"tag":"div"}}):_vm._e(),_vm._l((_vm.selectedCategories),function(category){return _c('Cell',{key:category.name,attrs:{"value":!_vm.date ? null : _vm.getClientStatDetails(1, _vm.date, category.name),"type":_vm.date ? category.format : null,"tag":"div"}})})],2):_vm._e()],1)})],2):(_vm.allowedGoldenMetricsClients.length)?_c('TableSticky',{class:{
        'template-goldenmetrics__sticky--periods':
          _vm.displayStatistic && _vm.isPeriod,
      },attrs:{"fields":[
        { key: 'client', size: 'l' },
        { key: 'period', translation: 'goldenmetrics.period' },
        ..._vm.selectedCategories.map((c) => ({
          key: c.name,
          translation: `goldenmetrics.${c.name}`,
        })),
      ]}},_vm._l((_vm.allowedGoldenMetricsClients),function(client,i){return _c('div',{key:i},[_c('TableStickyRow',[_c('Cell',{staticClass:"is-size--l",attrs:{"value":client.client.name,"tag":"div"}}),_c('Cell',{attrs:{"value":_vm.period1Start,"type":"date","tag":"div"}}),_vm._l((_vm.selectedCategories),function(category){return _c('Cell',{key:category.name,attrs:{"value":_vm.getClientStatSum(client, 0, category.name),"type":category.format,"tag":"div"}})})],2),(_vm.displayStatistic && _vm.isPeriod)?_c('TableStickyRow',[_c('Cell',{staticClass:"is-size--l",attrs:{"value":" ","tag":"div"}}),(_vm.type === 'benchmark')?_c('Cell',{attrs:{"value":_vm.$t('goldenmetrics.benchmark'),"tag":"div"}}):_c('Cell',{attrs:{"value":_vm.period2Start,"type":"date","tag":"div"}}),_vm._l((_vm.selectedCategories),function(category){return _c('Cell',{key:category.name,attrs:{"value":_vm.getClientStatSum(client, 1, category.name),"type":category.format,"tag":"div"}})})],2):_vm._e()],1)}),0):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }